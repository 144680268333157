<template>
  <div>
    <div class="d-flex justify-content-center text-center mt-2">
      <b-row>
        <b-col md="12">
          <div>
            <h1>
              <strong
                >FICHE DE RENSEIGNEMENT AUX FRONTIERES TERRESTRES
                (ENTREE)</strong
              >
            </h1>
          </div>
        </b-col>

        <b-col md="12">
          <div>
            <h3>
              <strong>COMMISSARIAT DE POLICE DE:</strong>
              <strong>
                {{ validOutput.commissariat.nom }}
              </strong>
            </h3>
          </div>
        </b-col>
        <b-col md="12">
          <div>
            <small>(Réservé à l'agent de la police)</small>
          </div>
        </b-col>
        <!-- <b-col md="12">
          <div>
            ETABLISSEMENT <strong> {{ validOutput.etablis }} </strong>
          </div>
        </b-col> -->
      </b-row>
    </div>

    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col md="12" class="text-center text-dark">
          <strong> RENSEIGNEMENTS GÉNÉRAUX </strong>
        </b-col>
      </b-row>
    </div>

    <b-card-body class="nopadding">
      <div class="pl-1 pr-1">
        <b-row>
          <b-col md="12" class="mt-2" />
          <b-col md="4" class="">
            Date: <strong> {{ validOutput.date }} </strong>
          </b-col>
          <b-col md="4" class="text-center">
            Heure <strong> {{ validOutput.heure }} </strong>
          </b-col>
          <b-col md="4" class="text-right">
            NPI: <strong> {{ validOutput.npi }} </strong>
            <div>
              <small>(Réservé aux Béninois)</small>
            </div>
          </b-col>
          <b-col md="2" />
          <b-col md="2" />

          <!-- <b-col md="4">
            Heure: <strong> {{ validOutput.heureArrivee }} </strong>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            Chambre N° <strong> {{ validOutput.numeroChambre }} </strong>
          </b-col>
          <b-col
            md="4"
            class=" text-right"
          >
            Date de départ: <strong> {{ validOutput.dateDepart }} </strong>
          </b-col>
          <b-col md="2" /> -->
        </b-row>
      </div>
    </b-card-body>

    <!-- renseignement generaux -->
    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col md="12" class="text-center text-dark">
          <strong> IDENTITÉ </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <div class="pl-1 pr-1 mt-1">
        <b-row>
          <b-col md="3" class="mb-1">
            Nom: <strong> {{ validOutput.lastname }} </strong>
          </b-col>
          <b-col md="6" class="mb-1">
            Prénom: <strong> {{ validOutput.firstname }} </strong>
          </b-col>
          <b-col md="3" class="mb-1">
            Nom de jeune fille:
            <strong> {{ validOutput.maidenname }} </strong>
          </b-col>
          <b-col md="4" class="mb-1">
            Sexe: <strong> {{ validOutput.sexe }} </strong>
          </b-col>

          <b-col md="4" class="mb-1">
            Date de naissance:
            <strong> {{ validOutput.dateNaissance }} </strong>
          </b-col>
          <b-col md="4" class="mb-1 text-right">
            Lieu de naissance:
            <strong> {{ validOutput.lieuNaissance }} </strong>
          </b-col>
          <b-col md="12" class="mb-1">
            Nationalité: <strong> {{ validOutput.nationalite }} </strong>
          </b-col>
          <b-col md="6" class="mb-1">
            Adresse à l'étranger:
            <strong> {{ validOutput.adresseEtranger }} </strong>
          </b-col>
          <b-col md="6" class="mb-1">
            Mode d'hébergement à l'étranger:
            <strong> {{ validOutput.modeHebergementEtranger }} </strong>
            <div>
              <small>(Pour les résidents du Bénin)</small>
            </div>
          </b-col>
          <b-col md="6" class="mb-1">
            Adresse au Bénin: <strong> {{ validOutput.adresseBenin }} </strong>
          </b-col>
          <b-col md="6" class="mb-1">
            Mode d'hébergement au Bénin:
            <strong> {{ validOutput.modeHebergementBenin }} </strong>
            <div>
              <small>(Pour les non-résidents)</small>
            </div>
          </b-col>
          <b-col md="6" class="mb-1">
            Pays de provenance:
            <strong> {{ validOutput.paysProvenence }} </strong>
          </b-col>

          <b-col md="6" class="mb-1">
            Pays de résidence:
            <strong> {{ validOutput.paysResidence }} </strong>
          </b-col>
          <!-- <hr> -->
        </b-row>
      </div>
    </b-card-body>

    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col md="12" class="text-center text-dark">
          <strong> DESTINATION</strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body>
      <div v-if="validOutput.destination">
        <b-row>
          <b-col md="4" class="mb-1">
            Pays: <strong> {{ validOutput.destination.pays }} </strong>
          </b-col>
          <b-col md="4" class="mb-1">
            Ville: <strong> {{ validOutput.destination.ville }} </strong>
          </b-col>
          <b-col md="4" class="mb-1">
            {{form_frontiere_entree_label_q17_q18(validOutput.paysResidence)[1]}}
            <strong> {{ validOutput.dureeSejour }} </strong>
          </b-col>
          <b-col md="6" class="mb-1">
            <!-- Motif principal du voyage: -->
            {{form_frontiere_entree_label_q17_q18(validOutput.paysResidence)[0]}}
            <strong> {{ validOutput.motifVoyage }} </strong>
          </b-col>
          <b-col md="6" class="mb-1">
            Catégorie Socio-Professionnelle:
            <strong> {{ validOutput.categorieSocioPro }} </strong>
          </b-col>
        </b-row>
      </div>
    </b-card-body>

    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col md="12" class="text-center text-dark">
          <strong> VOYAGE </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body>
      <div v-if="validOutput.titreVoyage">
        <b-row>
          <b-col md="12" class="mb-1">
            Titre du voyage:
            <strong> {{ validOutput.titreVoyage.titre }} </strong>
          </b-col>
          <b-col md="6" class="mb-1">
            Date et lieu de délivrance:
            <strong> {{ validOutput.titreVoyage.dateDelivrance }} </strong>
          </b-col>
          <b-col md="6" class="mb-1">
            Date d'expiration:
            <strong> {{ validOutput.titreVoyage.dateExpiration }} </strong>
          </b-col>
          <b-col md="6" class="mb-1">
            Numero du véhicule:
            <strong> {{ validOutput.numeroVehicule }} </strong>
          </b-col>
          <b-col md="6" class="mb-1">
            Nom et Prénom du conducteur:
            <strong> {{ validOutput.fullnameConducteur }} </strong>
          </b-col>
          <b-col md="12" class="mb-1">
            Numero du permis de conduire:
            <strong> {{ validOutput.numeroPermisConduire }} </strong>
          </b-col>

          <b-col md="12" class="mb-1">
            Accompagner de:
            <strong> {{ validOutput.nombreAccompagnants }} </strong>
            <small>(Nombre d'enfants)</small>
          </b-col>
        </b-row>
      </div>
    </b-card-body>

    <!-- <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> RESERVE À L'ADMINISTRATION</strong>
        </b-col>
      </b-row>
    </div> -->
    <!-- <b-card-body>
      <div>
        <b-row>
          <b-col
            md="12"
            class="mb-1"
          >
            Numéro Visa: <strong> {{ validOutput.reserveAdministration.visa.numeroVisa }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Délivrée par: <strong> {{ validOutput.reserveAdministration.visa.delivreePar }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Date de délivrance: <strong> {{ validOutput.reserveAdministration.visa.dateDelivrance }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Duréé de validité: <strong> {{ validOutput.reserveAdministration.visa.dureeValidite }} </strong>
          </b-col>
        </b-row>
      </div>
    </b-card-body> -->
    <!-- <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> OBSERVATIONS</strong>
        </b-col>
      </b-row>
    </div> -->
    <b-card-body>
      <div>
        <b-row>
          <b-col md="12" class="mb-1">
            {{ validOutput.observations }}
          </b-col>
          <!-- <b-col
            md="12"
            class="mb-1"
          >
            Signature <strong> {{ validOutput.signature }} </strong>
          </b-col> -->
        </b-row>
      </div>
    </b-card-body>
    <!-- <hr class="invoice-spacing mt-5"> -->
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BFormInput,
  BFormGroup,
  BRow,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Logo from "@core/layouts/components/Logo.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BFormInput,
    BFormGroup,
    BRow,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
  },
  props: {
    validOutput: Object,
  },
  data() {
    return {};
  },

  mounted() {
    // this.validOutput.agrementMinistere = 'non'
    console.log("this.validOutput::: ", this.validOutput);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.title-background {
  background-color: #ababab;
}

.tableHeadFontSize {
  font-size: 10px;
}
</style>
